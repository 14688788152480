import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	ResolveFn,
} from '@angular/router';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { getRouteParameter } from '@webapp-nx-repo/lib-shared-common';
import { HttpCrewNoteService } from '../../services/http-crew-note.service';
import { EMPTY_CREW_NOTE, IBoardQualification, ICrewNote } from '../../models/crew-note';
import { IShip } from '../../models/ship';

export type NotaEquipaggioResolverT = [ICrewNote, IShip[], IBoardQualification[]];

export const notaEquipaggioResolver: ResolveFn<NotaEquipaggioResolverT> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<NotaEquipaggioResolverT> => {
	const service = inject(HttpCrewNoteService);

	const item_id = getRouteParameter(
		route.paramMap,
		'Identificativo della nota equipaggio',
		'crew_note_id'
	);

	if (item_id === undefined) {
		return throwError(() => 'undefined crew_note_id');
	}

	return forkJoin([
        item_id === 0
            ? of<ICrewNote>(EMPTY_CREW_NOTE)
            : service.crew_notes.get(item_id),  // crew_note +
                                                //     crew_note_ships:id,crew_note_id,ship_id +
                                                //         ship:id,short_name +
                                                //         crew_note_see_employees:id,crew_note_ship_id,sea_employee_id +
                                                //             sea_employee:id,user_info_id +
                                                //                 user_info:id,name,surname

        service.ships.all(),                    // used for select input field
        service.board_qualifications.all(),     // used for select input field
    ]);
};
