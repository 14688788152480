import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuService } from '@webapp-nx-repo/lib-shared-shell';
import { ICrewNote } from '../../models/crew-note';
import { HttpCrewNoteService } from '../../services/http-crew-note.service';

export type CrewNotesResolverT = ICrewNote[];


export const crewNotesResolver: ResolveFn<CrewNotesResolverT> = 
(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
)
: Observable<CrewNotesResolverT> => {

    const crewNoteService = inject(HttpCrewNoteService);
    const menuService = inject(MenuService);
   
    const qs: [string, string][] = [
        ['year', `${menuService.header.yearSelected()}`]
    ];

    const crew_note_ids = route.paramMap.get('ids');
    if(crew_note_ids) {
        qs.push(['ids', crew_note_ids]);
    }

    return crewNoteService.crew_notes.all(qs);
}