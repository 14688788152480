<form [formGroup]="formData" autocomplete="off" novalidate class="flex flex-col">
            
    <!-- Nome, Cognome, cf, phone, sez, stato civile -->
    <div class="border rounded p-4 bg-white flex flex-col">
        
        <h2 class="py-4 px-2 flex items-end">
            <mat-icon class="mr-3">directions_boat</mat-icon>
            <span class="font-bold">Gente di mare</span>
        </h2>        

        <mat-form-field>
            <mat-label>Matricola</mat-label>                
            <input inputRefTrimValue matInput type="text" formControlName="matricola" required>
            @if(formData.controls.matricola.errors?.['required']) {
                <mat-error>Inserire la matricola</mat-error>
            }
            @if(formData.controls.matricola.errors?.['maxLength']) {
                <mat-error>matricola troppo lunga</mat-error>
            }
            @if(formData.controls.matricola.errors?.['minLength']) {
                <mat-error>matricola troppo corta</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>Compartimento</mat-label>
            <input inputRefTrimValue matInput type="text" formControlName="compartimento" required>
            @if(formData.controls.compartimento.errors?.['required']) {
                <mat-error>Inserire il compartimento</mat-error>
            }
            @if(formData.controls.compartimento.errors?.['maxLength']) {
                <mat-error>Compartimento troppo lungo</mat-error>
            }
            @if(formData.controls.compartimento.errors?.['minLength']) {
                <mat-error>Compartimento troppo corto</mat-error>
            }
        </mat-form-field>

    </div>

    <lib-ok-cancel 
        label_ok="SALVA"
        label_cancel="Indietro"
        [disable_ok]="formData.invalid || formData.pristine || saving()" 
        [loading_ok]="saving()"
        (onOK)="save()"
        (onCancel)="this.onCancel.emit()" />
    
</form>

@if (error()) {
    <lib-error-alert>{{ error() }}</lib-error-alert>
}
