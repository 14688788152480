import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	ResolveFn,
} from '@angular/router';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { getRouteParameter } from '@webapp-nx-repo/lib-shared-common';
import { HttpCrewNoteService } from '../../services/http-crew-note.service';
import { EMPTY_CREW_NOTE, IBoardQualification, ICrewNote, ICrewNoteShip, ISeaEmployee } from '../../models/crew-note';
import { IShip } from '../../models/ship';

export type NotaEquipaggioShipResolverT = [ICrewNoteShip, ISeaEmployee[], IBoardQualification[]];

export const notaEquipaggioShipResolver: ResolveFn<NotaEquipaggioShipResolverT> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<NotaEquipaggioShipResolverT> => {
	const service = inject(HttpCrewNoteService);

	const item_id = getRouteParameter(
		route.paramMap,
		'Identificativo della nota equipaggio',
		'crew_note_ship_id'
	);

	if (item_id === undefined) {
		return throwError(() => 'undefined crew_note_ship_id');
	}

	return forkJoin([
        service.crew_note_ship.get(item_id),
        service.sea_employee.all(),             // used for select input field
        service.board_qualifications.all(),     // used for select input field
    ]);
};
