import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    signal,
    input,
    output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { _isNumberValue } from '@angular/cdk/coercion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DateTime } from 'luxon';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { BaseCrudEntity } from '@webapp-nx-repo/lib-shared-shell';
import {
    CityControlComponent,
    ErrorAlertComponent,
    HttpCrudService,
    ICity,
    ICityDetails,
    InputRefTrimValueDirective,
    OkCancelComponent,
} from '@webapp-nx-repo/lib-shared-common';
import { forkJoin } from 'rxjs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EMPTY_SEA_EMPLOYEE, ISeaEmployee } from '../../models/crew-note';
import { HttpCrewNoteService } from '../../services/http-crew-note.service';
import { IUserInfo } from '@webapp-nx-repo/lib-shared-auth';

@Component({
    selector: 'lib-gp-sea-employee-form',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatProgressBarModule,
        InputRefTrimValueDirective,
        ErrorAlertComponent,
        OkCancelComponent,
    ],
	templateUrl: './sea-employee-form.component.html',
	styleUrl: './sea-employee-form.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeaEmployeeFormComponent extends BaseCrudEntity<ISeaEmployee> {
	private readonly fb = inject(FormBuilder);
    protected readonly httpCrewNoteService = inject(HttpCrewNoteService);

    user_info = input.required<IUserInfo>();
    seaEmployee = signal<ISeaEmployee>(EMPTY_SEA_EMPLOYEE);
    onSubmit = output<ISeaEmployee>();
    onCancel = output<void>();
    
	formData = this.fb.group({
        matricola: this.fb.control<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(191)]),
        compartimento: this.fb.control<string | null>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(191)]),
    });
    

	protected override _ngOnInit(): void {
        const qs: [string, string][] = [
            ['user_info_id', this.user_info().id.toString()],
        ];
        this.httpCrewNoteService.sea_employee.all(qs).pipe(   
            takeUntilDestroyed(this.destroyRef)
        ).subscribe({
            next: (items) => {
                if (items.length > 0) {
                    this.seaEmployee.set(items[0]);
                    this.formData.patchValue({
                        matricola: items[0].matricola,
                        compartimento: items[0].compartimento,
                    });
                }
            },
        });
	}
    

	// Implementation of: BaseCrudEntity<ISeaEmployee>
	//
	protected get_item(): ISeaEmployee {
		const item: ISeaEmployee = {
			id: this.seaEmployee().id,
			user_info_id: this.user_info().id,
			matricola: this.formData.value.matricola!,
            compartimento: this.formData.value.compartimento!,
		};
		return item;
	}
	protected get_http_crud_service(): HttpCrudService<ISeaEmployee> {
		return this.httpCrewNoteService.sea_employee;
	}
	protected on_save_confirm_dialog_mgs(): string[] {
		return [
			`Salvo i dati di profilo per l\'utente \"${this.user_info().name} ${this.user_info().surname}\" ?`,
		];
	}
	protected on_delete_confirm_dialog_mgs(): string[] {
		return [`Vuoi eliminare i dati ?`];
	}
	protected on_insert_complete_msg(): string {
		return 'Dati inseriti';
	}
	protected on_update_complete_msg(): string {
		return 'Dati aggiornat1';
	}
	protected on_delete_complete_msg(): string {
		return 'Dati eliminati';
	}

	protected override on_insert_complete_fn(info: ISeaEmployee): void {
        this.onSubmit.emit(info);
	}
	protected override on_update_complete_fn(info: ISeaEmployee): void {
        this.onSubmit.emit(info);
	}
	protected override on_delete_complete_fn(id: number): void {
        this.onSubmit.emit(this.seaEmployee());
	}

    protected override on_insert_complete_do_back(): boolean { return false; };
    protected override on_update_complete_do_back(): boolean { return false; };
    protected override on_delete_complete_do_back(): boolean { return false; };
}

