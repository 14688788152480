import { Route } from '@angular/router';
import { CREW_NOTE_ROUTER_TOKENS } from './feature.router-tokens';
import { NoteEquipaggioComponent } from '../components/note-equipaggio/note-equipaggio.component';
import { NoteEquipaggioMailComponent } from '../components/mail/note-equipaggio-mail.component';
import { crewNotesResolver } from '../components/mail/note-equipaggio.resolver';
import { NotaEquipaggioComponent } from '../components/nota-equipaggio/nota-equipaggio.component';
import { notaEquipaggioResolver } from '../components/nota-equipaggio/nota-equipaggio-resolver';
import { NotaEquipaggioShipComponent } from '../components/nota-equipaggio-ship/nota-equipaggio-ship.component';
import { notaEquipaggioShipResolver } from '../components/nota-equipaggio-ship/nota-equipaggio-ship-resolver';

export const noteEquipaggioRoutes: Route[] = [
	{
		path: CREW_NOTE_ROUTER_TOKENS.HOME,
        loadComponent: () => import('../components/note-equipaggio/note-equipaggio.component').then(c => c.NoteEquipaggioComponent),
	},
	{
		path: CREW_NOTE_ROUTER_TOKENS.MAIL,
        loadComponent: () => import('../components/mail/note-equipaggio-mail.component').then(c => c.NoteEquipaggioMailComponent),        
		resolve: {
			data: crewNotesResolver,
		},
	},
	{
		path: ':crew_note_id',
        loadComponent: () => import('../components/nota-equipaggio/nota-equipaggio.component').then(c => c.NotaEquipaggioComponent),
		resolve: {
			data: notaEquipaggioResolver,
		},
	},
    {
		path: ':crew_note_id/:crew_note_ship_id',
        loadComponent: () => import('../components/nota-equipaggio-ship/nota-equipaggio-ship.component').then(c => c.NotaEquipaggioShipComponent),
		resolve: {
			data: notaEquipaggioShipResolver,
		},
	},
];
